@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import 'swiper/css';
@import "swiper/css/pagination";
@import "swiper/css/navigation";
@import "plyr-react/dist/plyr.css";
@import "rc-slider/assets/index.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* ion-icon {
    z-index: 0;
    --ionicon-stroke-width: 40px;
  } */
  svg{
    display: inline;
  }
  h2 {
    @apply block text-2xl my-3 mx-0 font-bold;
  }
  h3 {
    @apply block text-xl my-4 mx-0 font-bold;
  }
  h4 {
    @apply block text-base my-5 mx-0 font-bold;
  }
  html {
    font-family: 'Roboto', sans-serif;
    scroll-behavior: smooth;   
  }
}

@layer components {
  .card {
    @apply bg-white rounded-md p-5 shadow-sm overflow-hidden dark:bg-gray-800 dark:text-white
  }
  .course-list-card{
    @apply flex  xs:flex-col md:flex-row overflow-hidden bg-white rounded-md shadow-sm dark:bg-gray-800 dark:text-white
  }
  .link{
    @apply text-sky-400 hover:text-sky-600 duration-150
  }
  .card-title{
    @apply text-sky-500 font-medium text-lg leading-5 dark:text-sky-400 hover:text-blue-400 duration-150
  }
  .input-field{
    @apply w-full border rounded px-3 py-2 focus:outline-none dark:bg-gray-800 dark:border-slate-700 dark:text-gray-300
  }
  .sidebar-tooltip {
    @apply absolute w-auto p-2 min-w-max left-8 z-50
    rounded-md shadow-md
    text-white bg-gray-900 
    text-xs font-bold
    transition-all duration-100 scale-0 origin-left;
  }
  .tab-btn {
    @apply flex items-center px-4 py-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:bg-gray-200 hover:border-gray-300 dark:hover:text-gray-300
  }
  /* .ion-icon {
    z-index: 0;
  } */
}

@media screen and (-webkit-min-device-pixel-ratio: 0) {
     
  input[type="range"]::-webkit-slider-thumb {
      width: 10px;
      -webkit-appearance: none;
      appearance: none;
      height: 10px;
      cursor: grab;
      background: #FFF;
      box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
      border-radius: 50%;
  }
  input[type="range"]::-webkit-slider-thumb:active {
    background-color: aqua;
    cursor: grabbing;
  }
}

.swiper-pagination {
	margin-bottom: 5px;

}
.swiper-pagination-bullet {
	padding: 5px 8px;
	border-radius: 0.25rem;
	width: auto;
	height: auto;
	text-align: center;
	font-size: 12px;
	color:#000;
	opacity: 1;
	background: rgba(0,0,0,0.1);
}
.swiper-pagination-bullet-active {
	color:#fff;
	background: #007aff;
}
