.sign {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: 25;
  overflow: hidden;
}
.sign__content {
  display: flex;
  z-index: 20;
  justify-content: center;
  align-items: center;
  width: 100vw;
  min-height: 100vh;
  margin-top: -4%;
  animation: fade 1s;
}
.sign__form {
  background-color: #1a191f;
  border: 1px solid #222028;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 40px 20px;
  width: 100%;
  max-width: 400px;
}
.sign__logo {
  display: block;
  margin-bottom: 40px;
}
.sign__logo a {
  max-width: 100%;
  width: auto;
}
.sign__logo img {
  width: 200px;
}

.sign__input {
  width: 100%;
  background-color: #222028;
  border: 2px solid transparent;
  height: 46px;
  position: relative;
  color: #fff;
  font-size: 16px;
  width: 100%;
  color: #fff;
  border-radius: 6px;
  padding: 0 20px;
}
.sign__input:focus {
  border-color: #f9ab00;
}

.sign__textarea {
  border: 2px solid transparent;
  height: 150px;
  position: relative;
  color: #fff;
  font-size: 16px;
  width: 100%;
  padding: 15px 20px;
  resize: none;
  background-color: #222028;
  border-radius: 6px;
}
.sign__textarea:focus {
  border-color: #f9ab00;
}
.sign__group {
  position: relative;
  margin-bottom: 20px;
  width: 100%;
}
.sign__group--checkbox {
  width: 100%;
  text-align: left;
}
.sign__group--checkbox input:not(:checked),
.sign__group--checkbox input:checked {
  position: absolute;
  left: -9999px;
}
.sign__group--checkbox input:not(:checked) + label,
.sign__group--checkbox input:checked + label {
  font-size: 14px;
  color: #fff;
  font-weight: normal;
  position: relative;
  cursor: pointer;
  padding-left: 37px;
  line-height: 22px;
  margin: 0;
}
.sign__group--checkbox input:not(:checked) + label a,
.sign__group--checkbox input:checked + label a {
  color: #f9ab00;
}
.sign__group--checkbox input:not(:checked) + label a:hover,
.sign__group--checkbox input:checked + label a:hover {
  color: #f9ab00;
  text-decoration: underline;
}
.sign__group--checkbox input:not(:checked) + label:before,
.sign__group--checkbox input:checked + label:before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  background-color: #222028;
  border-radius: 6px;
}
.sign__group--checkbox input:not(:checked) + label:after,
.sign__group--checkbox input:checked + label:after {
  font-family: 'Ionicons';
  content: '\f3ff';
  position: absolute;
  left: 0;
  top: 0;
  width: 22px;
  height: 22px;
  font-size: 24px;
  line-height: 22px;
  text-align: center;
  transition: 0.4s;
  color: #f9ab00;
}
.sign__group--checkbox input:not(:checked) + label:after {
  opacity: 0;
  transform: scale(0);
}
.sign__group--checkbox input:checked + label:after {
  opacity: 1;
  transform: scale(1);
}
.sign__group--checkbox label::-moz-selection {
  background: transparent;
  color: #fff;
}
.sign__group--checkbox label::selection {
  background: transparent;
  color: #fff;
}
.sign__btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 46px;
  width: 100%;
  border-radius: 6px;
  background-color: transparent;
  font-size: 14px;
  color: #fff;
  text-transform: uppercase;
  border: 2px solid #f9ab00;
  margin-top: 15px;
  margin-bottom: 15px;
}
.sign__btn:hover {
  color: #fff;
  background-color: rgba(249,171,0,0.05);
}
.sign__text {
  margin-top: 15px;
  font-size: 14px;
  color: #fff;
}
.sign__text a {
  position: relative;
  color: #f9ab00;
}
.sign__text a:hover {
  color: #f9ab00;
  text-decoration: underline;
}
.sign__delimiter {
  font-size: 14px;
  color: #fff;
  line-height: 100%;
}
.sign__social {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  margin-top: 15px;
}
.sign__social a {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 44px;
  width: calc(33% - 10px);
  border-radius: 6px;
  color: #fff;
}
.sign__social a svg {
  height: auto;
  fill: #fff;
  transition: 0.4s;
}
.sign__social a.fb {
  background-color: #3b5999;
}
.sign__social a.fb svg {
  width: 10px;
}
.sign__social a.gl {
  background-color: #df4a32;
}
.sign__social a.gl svg {
  width: 18px;
}
.sign__social a.tw {
  background-color: #1da1f2;
}
.sign__social a.tw svg {
  width: 18px;
}
.sign__social a:hover {
  background-color: #fff;
}
.sign__social a:hover.fb svg {
  fill: #3b5999;
}
.sign__social a:hover.gl svg {
  fill: #df4a32;
}
.sign__social a:hover.tw svg {
  fill: #1da1f2;
}
@media (min-width: 576px) {
  .sign__form {
    padding: 50px 60px;
  }
  .sign__logo {
    margin-bottom: 20px;
  }
}

@keyframes fade {
  from {
    transform: translateY(-100px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.closeBtn{
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.closeIcon{
  color: rgba(249,170,0,0.8);
  transition: all .4s;
}
.closeIcon:hover{
  color: rgba(249,170,0)
}

.signup_text {
  margin-top: 15px;
  font-size: 14px;
  color: #fff;
}
.signup_text button {
  position: relative;
  color: #f9ab00;
}
.signup_text button:hover {
  color: #f9ab00;
  text-decoration: underline;
}
.error{
  color: #df4a32;
}
.dark-toast{
  background-color: #1a191f;
}